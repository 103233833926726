export const stringToDate = (dateString: string) => {
  if (dateString === "") {
    return "";
  }

  const dateParts = dateString.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 because month is zero-based in Date object
  const day = parseInt(dateParts[2], 10);
  const dateObject = new Date(year, month, day);

  return dateObject;
};

export const toDateFormat = (dateString: string) => {
  if (dateString === "") {
    return "";
  }

  // Parse the date string
  const parts = dateString.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const day = parseInt(parts[2]);

  // Create a new Date object
  const date = new Date(year, month - 1, day);

  // Format the date
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("uk-UA", options);
};
