import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import { resetUserState } from "../redux/slices/userSlice";
import ToastNotification from "./ToastNotification";
import { resetProductsState } from "../redux/slices/productsSlice";
import { resetOrdersState } from "../redux/slices/ordersSlice";
import { resetFiltersState } from "../redux/slices/filterSlice";
import { resetToastsState } from "../redux/slices/toastSlice";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthorized, partnerName } = useSelector((state: RootState) => state.user);

  const onLogOut = () => {
    if (window.confirm('Вийти із особистого кабінету?')) {
      dispatch(resetUserState());
      dispatch(resetProductsState());
      dispatch(resetOrdersState());
      dispatch(resetFiltersState());
      dispatch(resetToastsState());
      
      navigate("/");
    };
  };

  let headerTitle = 'Онлайн кабінет замовлень';
  if (partnerName) {
    headerTitle = headerTitle + ' - ' + partnerName;
  }
  
  return (
    <div>
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <div
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          <svg className="bi me-2" width="40" height="32">
            <use xlinkHref="/bootstrap"></use>
          </svg>
          <span className="fs-4">{headerTitle}</span>
        </div>
        <div>
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/">
                <span className="nav-link active" aria-current="page">
                    Головна
                </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contacts">
                <span className="nav-link">
                    Контакти
                </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about">
                <span className="nav-link">
                    Про нас
                </span>
            </Link>
          </li>
          {isAuthorized && (<li className="nav-item">
            <span className="btn nav-link link-danger" onClick={onLogOut}>
              Вийти
            </span>    
          </li>)}
        </ul>
        </div>
      </header>
      <ToastNotification />
    </div>
  );
};

export default Header;