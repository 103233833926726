import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './scss/app.scss';

import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import EditOrder from './pages/EditOrder';
import NotFound from './pages/NotFound';

import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="container-fluid App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/order/:id' element={<EditOrder />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;