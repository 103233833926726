import React from "react";
import { ProductItemType } from "../redux/slices/productsSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { addProductToOrder, deleteProductFromOrder, updateProductCount } from "../redux/slices/ordersSlice";

const ProductItem: React.FC<ProductItemType> = ({ id, productCode, name, baseUnitName, categoryId, price }) => {
  const navParams = useParams();
  const dispatch = useDispatch();
  const { orders } = useSelector((state: RootState) => state.orders);
  const { products } = useSelector((state: RootState) => state.products);
  const currentOrder = orders.find((obj) => obj.id === navParams.id);
  if (!currentOrder) {
    //navigate(-1);
  };

  let itemValueInOrder = '';
  const itemInOrder = currentOrder?.products?.find((obj) => obj.id === id);
  if (itemInOrder) {
    itemValueInOrder = itemInOrder.count.toString();
  };

  const [ countValue, setCountValue ] = React.useState(itemValueInOrder);

  const currentProduct = products.find((obj) => obj.id === id);

  const onClickAdd = () => {
    setCountValue((prev) => {
      const currentCount = (prev === '' || isNaN(prev)) ? 0 : parseInt(prev);
      const newCount = currentCount + 1;
      return newCount.toString();
    });

    dispatch(
      addProductToOrder({
        orderId: currentOrder?.id,
        productObject: currentProduct,
    }));
  };

  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const regex = /^\d*\.?\d{0,1}$/;
    if (inputValue === '' || inputValue === '0') {
      setCountValue('');
      dispatch(
        deleteProductFromOrder({
          orderId: currentOrder?.id,
          productId: id,
        })
      );
    } else if (regex.test(inputValue)) {
      // Check if the value matches the regex pattern
      setCountValue(inputValue);
      const count = parseFloat(inputValue).toFixed(1);
      const countAsNumber = parseFloat(count);
      dispatch(
        updateProductCount({
          orderId: currentOrder?.id,
          productObject: currentProduct,
          newCount: countAsNumber,
        })
      );
    }
  };

  return (
    <div className="col-3 d-flex">
      <div className="card shadow-sm flex-fill">
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{name}</h5>
          <p className="card-text text-body-secondary m-0">Код: {productCode}</p>
          <p className="card-text text-body-secondary m-0">Од.виміру: {baseUnitName}</p>
          <p className="card-text text-body-secondary m-0">Ціна: {price} грн</p>
          <div className="row g-2  mt-auto">
            <div className="col-6">
              <input className="form-control" type="number" value={countValue} onChange={e => handleCountChange(e)} />
            </div>
            <div className="col-6">
              <span onClick={onClickAdd} className="btn btn-outline-primary">
                Додати
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
