import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

const ToastNotification: React.FC = () => {
  const { toasts } = useSelector((state: RootState) => state.toasts);

  return (
    <div className="toast-container position-fixed bottom-0 start-0 pb-5 p-3">
      {toasts.map((item, index) => {
        return (
          <div
            key={index}
            id="liveToast"
            //className={`toast ${show ? 'show' : ''}`}
            className="toast show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <div className="px-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-square-fill" viewBox="0 0 16 16">
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                </svg>
              </div>
              <strong className="me-auto">{item.header}</strong>
              <small>...</small>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
            <div className="toast-body">{item.message}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ToastNotification;
