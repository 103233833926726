import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../utils/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    onNext,
    onPrevious,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav aria-label="pagination">
      <ul className={classnames("pagination pagination-sm m-0", "justify-content-center")}>
        <li
          className={classnames("page-item", {
            disabled: currentPage === 1,
          })}
          onClick={currentPage === 1 ? () => {} : onPrevious}
        >
          <p className="page-link btn btn-link">Попередня</p>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">{DOTS}</li>;
          }

          return (
            <li
              key={index}
              className={classnames("page-item", {
                active: pageNumber === currentPage,
              })}
              aria-current="page"
              onClick={() => onPageChange(pageNumber)}
            >
              <p className="page-link btn btn-link">{pageNumber}</p>
            </li>
          );
        })}

        <li
          className={classnames("page-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={currentPage === lastPage ? () => {} : onNext}
        >
          <p className="page-link btn btn-link">Наступна</p>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
