import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export enum Status {
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

export type loginData = {
  username: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  isAuthorized?: boolean;
  error: string;
};

export type loginProps = {
  username: string;
  password: string;
};

export interface systemUserState {
  username: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  isAuthorized: boolean;
  status: Status;
  user: string;
  userId: string;
  partnerName: string;
  error: string;
}

const initialState: systemUserState = {
  username: "",
  password: "",
  accessToken: "",
  refreshToken: "",
  isAuthorized: false,
  status: Status.LOADING,
  user: "",
  userId: "",
  partnerName: "",
  error: "",
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem('accessToken', accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken')
};

export const performLogin = createAsyncThunk(
  "user/tryToLogin",
  async (props: loginProps) => {
    const { username, password } = props;
    const response = await axios.post(
      "https://delivery.net.ua/ut_dev/hs/authorization/login",
      { username, password },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (response.data.accessToken) {
      setAccessToken(response.data.accessToken);
    };

    return response.data as systemUserState;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setIsAuthorized(state) {
      state.isAuthorized = true;
    },

    setIsUnauthorized(state) {
      state.isAuthorized = false;
    },

    resetUserState: state => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(performLogin.pending, (state, action) => {
      state.status = Status.LOADING;
      state.isAuthorized = false;
    });

    builder.addCase(performLogin.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.isAuthorized = false;
        state.status = Status.ERROR;
        state.accessToken = "";
        state.refreshToken = "";
        state.userId = "";
        state.error = action.payload.error;
        return;
      }
      state.status = Status.SUCCESS;
      state.isAuthorized = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userId = action.payload.userId;
      state.user = action.payload.username;
      state.partnerName = action.payload.partnerName;
    });

    builder.addCase(performLogin.rejected, (state, action) => {
      state.isAuthorized = false;
      state.status = Status.ERROR;
      state.accessToken = "";
      state.refreshToken = "";
      state.userId = "";
      state.error = "";
    });
  },
});

export const { setIsAuthorized, setIsUnauthorized, resetUserState } = userSlice.actions;

export default userSlice.reducer;
