import React from "react";

const Contacts: React.FC = () => {
  return (
    <div className="container">
      <div className="row g-2 justify-content-center">
        <div className="col-6">
            <h2>З'язок із нами</h2>
            <p>м. Полонне</p>
            <p>+380964453084</p>
            <p>+380970283013</p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
