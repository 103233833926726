import React from "react";

const About: React.FC = () => {
  return (
    <div className="container">
      <div className="row g-2 justify-content-center">
        <div className="col-6">
          <h2>Про нас</h2>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default About;
