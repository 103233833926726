import { createSlice } from "@reduxjs/toolkit";

interface ToastItem {
    id: string;
    header: string;
    message: string;
};

interface ToastSliceState {
    toasts: ToastItem[];
};

const initialState: ToastSliceState = {
    toasts: [],
};

export const toastSlice = createSlice({
    name: 'toast',    
    initialState,
    reducers: {
        showToast(state, action) {
            state.toasts.push(action.payload);
        },
        closeToast(state, action) {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        },
        resetToastsState: state => initialState,
    },
});

export const { showToast, closeToast, resetToastsState } = toastSlice.actions;

export default toastSlice.reducer;