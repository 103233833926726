import React from "react";
import "../scss/_variables.scss";

const Loading: React.FC = () => {
  return (
    <tr>
      <td colSpan={10}>
    <div className="loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        overflow="visible"
        fill="$orange"
        stroke="none"
      >
        <defs>
          <circle
            id="loader"
            r="4"
            cx="50"
            cy="50"
            transform="translate(0 -30)"
          />
        </defs>
        <use xlinkHref="#loader" transform="rotate(45 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.13s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(90 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.25s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(135 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.38s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(180 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.50s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(225 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.63s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(270 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.75s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(315 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="0.88s"
            repeatCount="indefinite"
          ></animate>
        </use>
        <use xlinkHref="#loader" transform="rotate(360 50 50)">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1s"
            begin="1.00s"
            repeatCount="indefinite"
          ></animate>
        </use>
      </svg>
    </div>
    </td>
    </tr>
  );
}

export default Loading;
