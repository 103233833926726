import React from "react";
import Orders from "../components/Orders";
import Login from "../components/Login";

import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const Home: React.FC = () => {
    const { isAuthorized } = useSelector((state: RootState) => state.user);

    return (
        <>
            {isAuthorized ? (<Orders />) : (<Login />)}
        </>
    );
};

export default Home;