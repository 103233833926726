import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../redux/store.ts";
import { setOrdersPeriodEnd, setOrdersPeriodStart } from "../redux/slices/filterSlice.ts";
import { fetchOrders } from "../redux/slices/ordersSlice.ts";

const Filters: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ordersPeriodStart, ordersPeriodEnd } = useSelector((state: RootState) => state.filters);

  const handleDateFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      dispatch(setOrdersPeriodStart(e.target.value));  
    };
  };

  const handleDateToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      dispatch(setOrdersPeriodEnd(e.target.value));  
    };
  };

  const handlePeriodChange = () => {
    dispatch(fetchOrders({ ordersPeriodStart, ordersPeriodEnd, navigate }));
  };

  return (
    <div className="row p-1">
      <h5>Відбір за періодом</h5>
      <div className="col-md-6">
        <input type="date" className="form-control" value={ordersPeriodStart} onChange={handleDateFromChange} />
      </div>
      <div className="col-md-6">
        {/* ordersPeriodEndInputValue.toString().substring(0,10) */}
        <input type="date" className="form-control" value={ordersPeriodEnd} onChange={handleDateToChange} />
      </div>
      <div className="col-md-12 pt-3">
        <button
          type="button"
          className="btn btn-secondary btn-і shadow-sm rounded"
          onClick={handlePeriodChange}
        >
          Застосувати
        </button>
      </div>
    </div>
  );
};

export default Filters;
