export const dateToString = (currentDate: Date) => {
  // // Extract year, month, and day
  // const year = date.getFullYear(); // Get the full year (YYYY)
  // const month = date.getMonth() + 1; // Get the month (0-11), add 1 to get (1-12)
  // const day = date.getDate(); // Get the day of the month (1-31)

  // // Format the date
  // const formattedDate = `${year}-${month}-${day}`;
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's zero-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDateString = `${year}-${month}-${day}`;

  return formattedDateString;
};
