import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import user from "./slices/userSlice.ts";
import orders from "./slices/ordersSlice.ts";
import products from "./slices/productsSlice.tsx";
import filters from "./slices/filterSlice.ts";
import toasts from "./slices/toastSlice.ts";

export const store = configureStore({
    reducer: {
        user,
        orders,
        products,
        filters,
        toasts
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type OrdersDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<OrdersDispatch>();