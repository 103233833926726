import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";

import { calcOrderTotalAmount } from "../../utils/calcOrderTotalAmount";
import { dateToString } from "../../utils/dateToString";
import { deepCopy } from "../../utils/deepCopyObject";
import { getCustomResponse } from "../../utils/getCustomResponse";
import { setupAxios } from "../../utils/apiUtils.js";
import { act } from "react-dom/test-utils/index.js";


export type OrderProductItemType = {
    id: string,
    productCode: string;
    name: string,
    baseUnitName: string,
    count: number,
    price: number,
    amount: number,
};

export type OrdersItemType = {
    id: string,
    date: string,
    deliveryDate: string,
    amount: number,
    status: string,
    comment: string,
    isAccepted: boolean,
    isTouched: boolean,
    products?: OrderProductItemType[],
};

export enum Status {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
};

interface OrdersSliceState {
    orders: OrdersItemType[];
    status: Status;
};

interface FetchOrdersParams {
    ordersPeriodStart: string;
    ordersPeriodEnd: string;
    navigate: Function;
};

interface SaveOrderParams {
    setOrderAccepted: boolean;
    orderId: string;
    navigate: Function;
};

interface DeleteOrderParams {
    orderId: string;
    navigate: Function;
};

const initialState: OrdersSliceState = {
    orders: [],
    status: Status.LOADING,
};


export const fetchOrders = createAsyncThunk(
    "orders/fetchOrders",
    async ( arg: FetchOrdersParams, {dispatch, getState} ) => {
        const { userId, accessToken } = (getState() as RootState).user;
        const { ordersPeriodStart, ordersPeriodEnd, navigate } = arg;
        const periodStart = ordersPeriodStart; //.substring(0,10).replaceAll("-","");
        const periodEnd = ordersPeriodEnd; //.substring(0,10).replaceAll("-","");
        const params = { userId,  periodStart, periodEnd };
        await setupAxios(axios, dispatch, navigate, accessToken);
        const response = await axios.get(
            "https://delivery.net.ua/ut_dev/hs/orders/getOrders",
            {params}       
          );

        return response.data as OrdersItemType[];
    }
);

export const saveOrder = createAsyncThunk(
    "orders/save",
    async (arg: SaveOrderParams, {dispatch, getState}) => {
        try {
            const { userId, accessToken } = (getState() as RootState).user;
            const { orders } = (getState() as RootState).orders;
            const { setOrderAccepted, orderId, navigate } = arg;
            const currentOrderToSave = orders.find((obj) => obj.id === orderId);
            const params = { userId, currentOrderToSave, setOrderAccepted };
            await setupAxios(axios, dispatch, navigate, accessToken);
            const response = await axios.post(
                "https://delivery.net.ua/ut_dev/hs/orders/saveOrder",
                {params}    
            );

            const customResponse = getCustomResponse(response);
            return customResponse;
        } catch(error) {
            return error;
        };
    }
);

export const deleteOrder = createAsyncThunk(
    "orders/delete",
    async (arg: DeleteOrderParams, {dispatch, getState}) => {
        const { userId, accessToken } = (getState() as RootState).user;
        const { orders } = (getState() as RootState).orders;
        const { orderId, navigate } = arg;
        const currentOrderToDelete = orders.find((obj) => obj.id === orderId);
        const params = { userId, currentOrderToDelete };
        await setupAxios(axios, dispatch, navigate, accessToken);
        const response = await axios.post(
            "https://delivery.net.ua/ut_dev/hs/orders/deleteOrder",
            {params}    
        );
        
        const customResponse = getCustomResponse(response);
        return customResponse;
    }
);

export const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setOrders(state, action: PayloadAction<OrdersItemType[]>) {
            state.orders = action.payload;
        },

        addNewOrder(state) {
            const newOrder: OrdersItemType = {
                id: 'new',
                date: dateToString(new Date()),
                deliveryDate: '',
                amount: 0,
                status: '',
                comment: '',
                isAccepted: false,
                isTouched: true,
                products: [],
            };
            state.orders.push(newOrder);
        },

        copyOrder(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload);
            if (!currentOrder) {
                return;
            };

            // here we might want to filter old products against updated available list of products

            const orderCopy = deepCopy(currentOrder);
            const newOrder = {...orderCopy, id:'new', date: dateToString(new Date()), deliveryDate: dateToString(new Date()), isTouched: true, isAccepted: false};
            state.orders.push(newOrder);
        },

        changeOrderDate(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload.orderId);
            if (!currentOrder) {
                return;
            };
            currentOrder.date = action.payload.orderDate;
            currentOrder.isTouched = true;    
        },

        setOrderAccepted(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload);
            if (!currentOrder) {
                return;
            };
            currentOrder.isAccepted = true;    
        },

        deleteOrderFromState(state, action) {
            state.orders = state.orders.filter((obj) => obj.id !== action.payload);   
        },

        changeOrderComment(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload.orderId);
            if (!currentOrder) {
                return;
            };
            currentOrder.comment = action.payload.comment;
            currentOrder.isTouched = true;    
        },
        
        addProductToOrder(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload.orderId);
            if (!currentOrder) {
                return;
            };

            const productInOrder = currentOrder?.products?.find((obj) => obj.id === action.payload.productObject.id);
            if (productInOrder) {
                productInOrder.count++;
                productInOrder.amount = parseFloat((productInOrder.count * productInOrder.price).toFixed(2));
            } else {
                // adding new product to order
                currentOrder?.products?.push({
                    ...action.payload.productObject,
                    count: 1,
                    amount: action.payload.productObject.price,
                });
            };
            currentOrder.amount = calcOrderTotalAmount(currentOrder?.products);
            currentOrder.isTouched = true;
        },

        updateProductCount(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload.orderId);
            if (!currentOrder) {
                return;
            };

            const productInOrder = currentOrder.products?.find((obj) => obj.id === action.payload.productObject.id);
            if (productInOrder) {
                productInOrder.count = action.payload.newCount;
                productInOrder.amount = productInOrder.price * productInOrder.count;
            } else {
                // adding new product to order
                currentOrder?.products?.push({
                    ...action.payload.productObject,
                    count: action.payload.newCount,
                    amount: parseFloat((action.payload.newCount * action.payload.productObject.price).toFixed(2))
                });
            };
            currentOrder.amount = calcOrderTotalAmount(currentOrder?.products);
            currentOrder.isTouched = true;
        },

        deleteProductFromOrder(state, action) {
            const currentOrder = state.orders.find((obj) => obj.id === action.payload.orderId);
            if (currentOrder) {
                currentOrder.products = currentOrder.products?.filter((obj) => obj.id !== action.payload.productId);
                currentOrder.amount = calcOrderTotalAmount(currentOrder.products);
                currentOrder.isTouched = true;
            }
        },

        resetOrdersState: state => initialState,
    },

    extraReducers: (builder) => {
        builder.addCase(fetchOrders.pending, (state, action) => {
            state.status = Status.LOADING;
            state.orders = [];
        });
        
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            state.orders = action.payload || [];
        });

        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.orders = [];
        });
    },
});

export const { setOrders, addNewOrder, copyOrder, setOrderAccepted, deleteOrderFromState, changeOrderDate, changeOrderComment, addProductToOrder, updateProductCount, deleteProductFromOrder, resetOrdersState } = ordersSlice.actions;

export default ordersSlice.reducer;