export const getCustomResponse = (response) => {
  const customResponse = {
    responseHeader: "",
    responseMessage: "",
    responseSuccesfull: false,
  };

  try {
    if (response.status === 200) {
      if (!response.data.error) {
        customResponse.responseHeader = "Успіх";
        customResponse.responseMessage = response.data?.status;
        customResponse.responseSuccesfull = true;
      } else {
        customResponse.responseHeader = "Помилка";
        customResponse.responseMessage = response.data?.status;
      }
    } else {
      // let's show message from server
      customResponse.responseHeader = "Помилка";
      customResponse.responseMessage = response.statusText;
    };
  } catch (error) {
    customResponse.responseHeader = "Помилка";
    customResponse.responseMessage = error as string;
  };

  return customResponse;
};