import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { addSelectedCategoryId, removeSelectedCategoryId } from "../redux/slices/filterSlice";

const ProductCategories: React.FC = () => {
  const { products, categories } = useSelector((state: RootState) => state.products);
  const { selectedCategoryIds } = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();

  const handleOnSelectCategory = (id: string) => {
    if (!selectedCategoryIds.includes(id)) {
      dispatch(addSelectedCategoryId(id));
    } else {
      dispatch(removeSelectedCategoryId(id));
    };
  };

  return (
    <div className="text-start">
      <div className="form-check">
        <h5 className="ps-1">Категорії товарів</h5>
        {categories.map((obj) => (
          <div key={obj.id} className="d-flex align-items-center m-1">
            <label key={obj.id} className="form-check-label" htmlFor="categoryCheckbox">
              {obj.name}
              <input
                className="form-check-input me-2"
                type="checkbox"
                checked={selectedCategoryIds.includes(obj.id)}
                //value={obj.id}
                onChange={() => handleOnSelectCategory(obj.id)}
              /> 
            </label>
            <span className="badge bg-secondary rounded-pill mx-3">{products.filter((el) => el.categoryId === obj.id).length}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCategories;
