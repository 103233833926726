import { v4 as uuidv4 } from "uuid";
import { closeToast, showToast } from "../redux/slices/toastSlice.ts";
import { resetUserState } from "../redux/slices/userSlice.ts";
import { resetProductsState } from "../redux/slices/productsSlice.tsx";
import { resetOrdersState } from "../redux/slices/ordersSlice.ts";
import { resetFiltersState } from "../redux/slices/filterSlice.ts";
import { resetToastsState } from "../redux/slices/toastSlice.ts";

export const setupAxios = async (axios, dispatch, navigate, token='') => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, OPTIONS";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  };

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Handle successful responses
      return response;
    },
    (error) => {
      // Handle response errors
      if (error.response && 
        (error.response.status === 401 || error.response.status === 498 || error.response.status === 400)) {
        // Unauthorized - Access token expired
        // Handle the 401 error globally, e.g., by redirecting to the login page
        // console.log("401 Unauthorized error:", error.response.data);
        localStorage.removeItem("accessToken");

        // Clear user data from Redux store
        // and auto redirect to login page
        dispatch(resetUserState());
        dispatch(resetProductsState());
        dispatch(resetOrdersState());
        dispatch(resetFiltersState());
        dispatch(resetToastsState());
        navigate("/");

        // Display toast notification to inform the user
        const toastID = uuidv4();
        const toastObj = {
          id: toastID,
          header: "Вихід із системи",
          message:
            "Термін дії сесії закінчився. Введіть повторно ваші дані для входу",
        };
        dispatch(showToast(toastObj));
        setTimeout(() => dispatch(closeToast(toastID)), 5000);
      } else {
        // Handle other response errors
        console.error("Response error:", error.response.data);
      }
      return Promise.reject(error);
    }
  );
};
