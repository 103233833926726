import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { dateToString } from "../../utils/dateToString";
import { orderLayoutOptions } from "../../utils/variables";

let defaultDateFrom = new Date();
defaultDateFrom.setDate(defaultDateFrom.getDate() - 60);

interface FilterSliceState {
    searchValue: string;
    selectedCategoryIds: string[];
    ordersPeriodStart: string;
    ordersPeriodEnd: string;
    ordersOnPage: number;
};

const initialState: FilterSliceState = {
    searchValue: '',
    selectedCategoryIds: [],
    ordersPeriodStart: dateToString(defaultDateFrom),
    ordersPeriodEnd: dateToString(new Date()),
    ordersOnPage: orderLayoutOptions[0],
};

export const filterSlice = createSlice({
    name: 'filters',    
    initialState,
    reducers: {
        setSearchValue(state, action) {
            state.searchValue = action.payload;
        },
        addSelectedCategoryId(state, action: PayloadAction<string>) {
            state.selectedCategoryIds.push(action.payload);
        },
        removeSelectedCategoryId(state, action: PayloadAction<string>) {
            state.selectedCategoryIds = state.selectedCategoryIds.filter((obj) => obj !== action.payload);
        },
        clearSelectedCategories(state) {
            state.selectedCategoryIds = [];
        },
        setOrdersPeriodStart(state, action) {
            state.ordersPeriodStart = action.payload;
        },
        setOrdersPeriodEnd(state, action) {
            state.ordersPeriodEnd = action.payload;
        },
        setOrdersOnPage(state, action) {
            state.ordersOnPage = action.payload;
        },

        resetFiltersState: state => initialState,
    },
});

export const { setSearchValue, addSelectedCategoryId, removeSelectedCategoryId, clearSelectedCategories,  setOrdersPeriodStart, setOrdersPeriodEnd, setOrdersOnPage, resetFiltersState } = filterSlice.actions;

// Selectors
export const selectFilterState = (state: RootState) => state.filters;
export const selectSearchValue = (state: RootState) => state.filters.searchValue;
export const selectSelectedCategoryIds = (state: RootState) => state.filters.selectedCategoryIds;
export const selectOrdersPeriodStart = (state: RootState) => state.filters.ordersPeriodStart;
export const selectOrdersPeriodEnd = (state: RootState) => state.filters.ordersPeriodEnd;

export default filterSlice.reducer;