import React from "react";
import { v4 as uuidv4 } from "uuid";
import ActionButton from "../components/ActionButton.tsx";
import { useNavigate, useParams } from "react-router-dom";
import OrderItemRow from "../components/OrderItemRow.tsx";

import ProductCategories from "../components/ProductCategories.tsx";
import Products from "../components/Products.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store.ts";
import { changeOrderComment, changeOrderDate, saveOrder } from "../redux/slices/ordersSlice.ts";
import { closeToast, showToast } from "../redux/slices/toastSlice.ts";
import { clearSelectedCategories, setSearchValue } from "../redux/slices/filterSlice.ts";
import { toDateFormat } from "../utils/stringToDate.ts";

const EditOrder: React.FC = () => {
  const navigate = useNavigate();
  const dispatchApp = useAppDispatch();
  const dispatch = useDispatch();
  const navParams = useParams();
  const { orders } = useSelector((state: RootState) => state.orders);
  const { partnerName } = useSelector((state: RootState) => state.user);

  const currentOrder = orders.find((obj) => obj.id === navParams.id);
  let defautProductPicker = false;
  let customHeader = `${currentOrder?.isTouched === true ? '*' : ''} Замовлення № ${currentOrder?.id} від ${toDateFormat(currentOrder?.date)}`;
  if (navParams.id === 'new') {
    defautProductPicker = true;
    customHeader = "Нове замовлення"
  };

  const orderDateInputValue = currentOrder?.date || '';

  const [productPicker, setProductPicker] = React.useState(defautProductPicker);
  const [orderDate, setOrderDate] = React.useState(orderDateInputValue);
  const [orderComment, setOrderComment] = React.useState(currentOrder?.comment);

  const handleOrderDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderDate(e.target.value);
    dispatch(
        changeOrderDate({
            orderId: currentOrder?.id,
            orderDate: e.target.value,
        })
    );    
  };

  const handleOrderCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderComment(e.target.value);
    dispatch(
        changeOrderComment({
            orderId: currentOrder?.id,
            comment: e.target.value,
        })
    );    
  };

  const onSaveOrder = async (setAccepted: boolean) => {
    const response = await dispatchApp(saveOrder({
      orderId:currentOrder?.id || '',
      setOrderAccepted: setAccepted,
      navigate: navigate,  
    }));

    if (response.payload?.responseSuccesfull) {
      navigate(-1);
    };

    const toastID = uuidv4();
    const toastObj = {
      id: toastID,
      header: response.payload?.responseHeader,
      message: response.payload?.responseMessage,
    };
    dispatch(showToast(toastObj));
    setTimeout(() => 
      dispatch(closeToast(toastID))
    , 5000);
  };

  const onClickEdit = () => {
    // reset search value
    dispatch(setSearchValue(""));
    dispatch(clearSelectedCategories());
    // show product list
    setProductPicker((prev) => !prev);
  };

  const onClickCloseOrder = () => {
    if (currentOrder?.isTouched === true) {
      if (window.confirm('Закрити замовлення без збереження?')) {
        navigate(-1);
        return;
      };
    } else {
      navigate(-1);
    };
  };

  const customBtnTitle = !productPicker
    ? "Додати товари"
    : "Завершити підбір";

  const orderItemsToDisplay = currentOrder?.products?.map((obj, index) => (
    <OrderItemRow
      key={index}
      ind={index}
      orderId={currentOrder.id}
      id={obj.id}
      productCode={obj.productCode}
      name={obj.name}
      scanCode={obj.id}
      baseUnitName={obj.baseUnitName}
      price={obj.price}
      count={obj.count}
      amount={obj.amount}
    />
  ));

  return (
    <div className="container orders">
      <h2>{customHeader}</h2>
      <div className="row g-2">
        <div className="col-3">
          <div className="border rounded border-secondary-subtle p-2 shadow-sm">
            <ActionButton title="Закрити" click={onClickCloseOrder} disabledState={false}/>
            <ActionButton title="Зберегти" click={() => onSaveOrder(false)} disabledState={currentOrder?.isAccepted || false} />
            <ActionButton title="Підтвердити" click={() => onSaveOrder(true)} disabledState={currentOrder?.isAccepted || false} />
            <ActionButton title={customBtnTitle} click={onClickEdit} disabledState={currentOrder?.isAccepted || false} />
            <div className="row row-cols-1 gy-10">
              <div className="col">{/* <Filters /> */}</div>
              <div className="col pt-3">
                { productPicker && <ProductCategories /> }
              </div>
            </div>
          </div>
        </div>
        <div className="col-9 pb-5">
          <div className="border rounded border-secondary-subtle p-2 shadow-sm">
            <div className="order_header col-md-12 pb-5">
              <form className="row g-3 text-start">
                <div className="col-md-3">
                  <label htmlFor="inputOrderNumber" className="form-label">
                    Номер
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={currentOrder?.id}
                    readOnly
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="inputOrderDate" className="form-label">
                    Дата
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={orderDate}
                    onChange={handleOrderDateChange}
                    readOnly={currentOrder?.isAccepted}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPatrnet" className="form-label">
                    Партнер
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={partnerName}
                    readOnly
                  />
                </div>
                <div className="col-md-9">
                  <label htmlFor="inputComment" className="form-label">
                    Коментар
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Текст коментаря до замовлення.."
                    value={orderComment}
                    onChange={handleOrderCommentChange}
                    readOnly={currentOrder?.isAccepted}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="inputComment" className="form-label">
                    Сума, грн.
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0"
                    value={currentOrder?.amount}
                    readOnly
                  />
                </div>
              </form>
            </div>
            {!productPicker ? (
              <div className="col-md-12">
                <table className="table table-primary table-hover table-bordered table-responsive caption-top">
                  <caption>{`Товари в замовленні. Всього позицій: ${currentOrder?.products?.length}`}</caption>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Код товару</th>
                      <th scope="col">Назва</th>
                      <th scope="col">ШК</th>
                      <th scope="col">Од. виміру</th>
                      <th scope="col">Кількість</th>
                      <th scope="col">Ціна</th>
                      <th scope="col">Сума</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>{orderItemsToDisplay}</tbody>
                </table>
              </div>
            ) : (
              <Products />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
