import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Orders from "./Orders";
import { RootState } from "../redux/store";
import { loginProps, performLogin, Status } from "../redux/slices/userSlice";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthorized, username, status, error } = useSelector((state: RootState) => state.user);
  const [userName, setUserName] = React.useState(username);
  const [userPassword, setUserPassword] = React.useState('');


  const loginDataSet: loginProps = {
    username: userName,
    password: userPassword,
  };

  const onClickLogin = (e) => {
    e.preventDefault();
    dispatch(performLogin(loginDataSet));
  };

  if (isAuthorized) {
    return <Orders />;
  };

  return (
    <div className="container text-center">
      <div className="row justify-content-center pt-5">
        <div className="col-sm-4">
          {status !== Status.ERROR && <h1 className="text-center login-title">
            Для входу в систему введіть ваш логін та пароль
          </h1>}
          {status === Status.ERROR && <div className="col-12">
            <div className="alert alert-danger d-flex align-items-center" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <p className="m-0">{error}</p>
            </div>  
          </div>}
          <div className="account-wall">
            <img
              className="profile-img"
              src="https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120"
              alt="account logo"
            />
            <form className="form-signin">
              <input
                type="text"
                className="form-control"
                placeholder="логін"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
                autoFocus
              />
              <input
                type="password"
                className="form-control"
                placeholder="пароль"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                required
              />
              <button
                className="btn btn-lg btn-primary btn-block"
                onClick={(e) => onClickLogin(e)}
              >
                Вхід
              </button>
              {/* <label className="checkbox pull-left">
                <input type="checkbox" value="remember-me" />
                Запамятати мене
              </label> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
