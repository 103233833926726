import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { RootState } from "../redux/store";
import ProductItem from "./ProductItem";
import { setSearchValue } from "../redux/slices/filterSlice";
import Pagination from "./Pagination";

const displayLayoutOptions = [8, 16, 24, 32];

const Products: React.FC = () => {
  const dispatch = useDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = React.useState("");
  const [itemsOnPage, setItemsOnPage] = React.useState(displayLayoutOptions[0]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { products } = useSelector((state: RootState) => state.products);
  const { searchValue, selectedCategoryIds } = useSelector(
    (state: RootState) => state.filters
  );

  let filteredProducts = products;
  if (searchValue) {
    filteredProducts = products.filter(
      (obj) =>
        obj.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        obj.productCode.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  if (selectedCategoryIds.length) {
    filteredProducts = filteredProducts.filter((obj) =>
      selectedCategoryIds.includes(obj.categoryId)
    );
  }

  const currentTableData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsOnPage;
    const lastPageIndex = firstPageIndex + itemsOnPage;
    return filteredProducts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, itemsOnPage, filteredProducts]);


  const productsToShow = currentTableData.map((obj, index) => (
    <ProductItem
      key={obj.id}
      id={obj.id}
      productCode={obj.productCode}
      name={obj.name}
      baseUnitName={obj.baseUnitName}
      categoryId={obj.categoryId}
      price={obj.price}
    />
  ));

  const updateSearchValue = React.useCallback(
    debounce((str: string) => {
      dispatch(setSearchValue(str));
    }, 1000),
    []
  );

  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchInput(event.target.value);
    updateSearchValue(event.target.value);
  };

  const onClickClear = () => {
    setSearchInput("");
    dispatch(setSearchValue(""));
    inputRef.current?.focus();
  };

  const onChangeDisplayLayout = (e) => {
    setCurrentPage(1);
    setItemsOnPage(Number(e.target.value))
  };


  return (
    <div className="col-md-12 order_table">
      <div className="container-fluid text-center row-gap-3">
        <div className="row g-3 mb-2">
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <input
                ref={inputRef}
                type="text"
                className="form-control"
                placeholder="Для пошуку товару введіть текст"
                value={searchInput}
                onChange={onChangeSearchInput}
              />
              <label htmlFor="floatingInput">
                Для пошуку товару введіть частину назви або код
              </label>
              {!searchInput && (
                <svg
                  className="search-icon"
                  height="30px"
                  width="30px"
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M448.3,424.7L335,311.3c20.8-26,33.3-59.1,33.3-95.1c0-84.1-68.1-152.2-152-152.2c-84,0-152,68.2-152,152.2  s68.1,152.2,152,152.2c36.2,0,69.4-12.7,95.5-33.8L425,448L448.3,424.7z M120.1,312.6c-25.7-25.7-39.8-59.9-39.8-96.3  s14.2-70.6,39.8-96.3S180,80,216.3,80c36.3,0,70.5,14.2,96.2,39.9s39.8,59.9,39.8,96.3s-14.2,70.6-39.8,96.3  c-25.7,25.7-59.9,39.9-96.2,39.9C180,352.5,145.8,338.3,120.1,312.6z" />
                </svg>
              )}
              {searchInput && (
                <svg
                  className="search-icon clear"
                  onClick={onClickClear}
                  height="30px"
                  width="30px"
                  version="1.1"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <g
                      id="Icon-Close"
                      transform="translate(381.000000, 231.000000)"
                    >
                      <polyline
                        id="Fill-16"
                        points="-370.7,-174.7 -373,-177 -327,-223 -324.7,-220.7 -370.7,-174.7    "
                      />
                      <polyline
                        id="Fill-17"
                        points="-327,-174.7 -373,-220.7 -370.7,-223 -324.7,-177 -327,-174.7    "
                      />
                    </g>
                  </g>
                </svg>
              )}
            </div>
          </div>
        </div>
        <div className="row g-3 mb-2">
          {productsToShow}
        </div>
        <div className="row g-3 mt-5 ">
          <div className="col-8">
            <Pagination
              currentPage={currentPage}
              totalCount={filteredProducts.length}
              pageSize={itemsOnPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPrevious={() => setCurrentPage((prev) => prev - 1)}
              onNext={() => setCurrentPage((prev) => prev + 1)}
            />
          </div>
          <div className="col-4">
            <div className="row g-0">
              <div className="col-6">
                <span>Показувати по:</span>
              </div>
              <div className="col-6">
                <select 
                  className="form-select form-select-sm m-0" 
                  onChange={(e) => onChangeDisplayLayout(e)}
                  defaultValue={displayLayoutOptions[0]}
                >
                {displayLayoutOptions.map((el, index) => (
                  <option key={index} value={el}>{el} елементів</option>
                ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
