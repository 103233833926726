import React from "react";

type ActionButtonType = {
  title: string;
  click: Function;
  disabledState: boolean;
};

const ActionButton: React.FC<ActionButtonType> = ({ title, click, disabledState }) => {
  return (
    <div className="d-grid gap-2 p-1">
      <button
        className="btn btn-outline-success shadow-sm rounded"
        type="button"
        onClick={() => click()}
        disabled={disabledState}
      >
        {title}
      </button>
    </div>
  );
};

export default ActionButton;
