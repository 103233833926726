import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import editLogo from "../assets/img/edit_logo.svg";
import deleteLogo from "../assets/img/deleteLogo.svg";
import verifiedLogo from "../assets/img/verifiedLogo.svg";
import savedLogo from "../assets/img/savedLogo.svg";
import copyLogo from "../assets/img/copyLogo.svg";
import { OrderProductItemType  } from "../redux/slices/ordersSlice";
import { useAppDispatch } from "../redux/store.ts";
import { useDispatch } from "react-redux";
import { closeToast, showToast } from "../redux/slices/toastSlice.ts";
import { saveOrder, copyOrder, setOrderAccepted, deleteOrder, deleteOrderFromState } from "../redux/slices/ordersSlice.ts";
import { toDateFormat } from "../utils/stringToDate.ts";


export type OrdersItemPropType = {
    ind: number,
    id: string,
    date: string,
    deliveryDate: string,
    amount: number,
    status: string,
    isAccepted: boolean,
    products: OrderProductItemType[],
};

const OrderRow: React.FC<OrdersItemPropType> = ({ ind, id, date, deliveryDate, amount, status, isAccepted }) => {
  const dispatchApp = useAppDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customAcceptedLogo = isAccepted ? verifiedLogo : savedLogo;
  
  const handleClickVerify = async () => {
    if (isAccepted) {
      window.alert('Вибране замовлення вже підтверджене!');
      return;
    };

    if (window.confirm('Після успішного підтвердженя замовлення не доступне для редагування. Підтвердити вибране замовлення?')) {
      const response = await dispatchApp(saveOrder({
        orderId: id || '',
        setOrderAccepted: true,
        navigate: navigate,  
      }));
      
      if (response.payload.responseSuccesfull) {
        dispatch(setOrderAccepted(id));
      };
      
      const toastID = uuidv4();
      const toastObj = {
        id: toastID,
        header: response.payload.responseHeader,
        message: response.payload.responseMessage,
      };
      dispatch(showToast(toastObj));
      setTimeout(() => 
        dispatch(closeToast(toastID))
      , 5000);
    };
  };

  const handleClickDelete = async () => {
    if (isAccepted) {
      window.alert('Видалити можна тільки не підтверджене замовлення!');
      return;
    };

    if (window.confirm('Замовлення буде видалено. Продовжити?')) {
      const response = await dispatchApp(deleteOrder({
        orderId: id || '',
        navigate: navigate 
      }));

      if (response.payload.responseSuccesfull) {
        dispatch(deleteOrderFromState(id));
      };

      const toastID = uuidv4();
      const toastObj = {
        id: toastID,
        header: response.payload.responseHeader,
        message: response.payload.responseMessage,
      };
      dispatch(showToast(toastObj));
      setTimeout(() => 
        dispatch(closeToast(toastID))
      , 5000);
    };
  };

  const handleClickCopy = () => {
    if (window.confirm('Створити нове замовлення копіюванням вибраного?')) {
      dispatch(copyOrder(id));
      navigate("/order/new");
    };
  };

  return (
    <tr>
      <th scope="row">
        {ind}
      </th>
      <td>{id}</td>
      <td className="text-truncate">{toDateFormat(date)}</td>
      <td className="text-truncate">{toDateFormat(deliveryDate)}</td>
      <td>{amount} грн</td>
      <td className="text-truncate">{status}</td>
      <td>
        <div className="btn btn-link p-0" onClick={handleClickVerify}>
          <img src={customAcceptedLogo} alt="verified logo" />
        </div>
      </td>
      <td>
        <div className="btn btn-link p-0" onClick={handleClickCopy}>
          <img src={copyLogo} alt="copy logo" />
        </div>
      </td>
      <td>
        <Link to={`/order/${id}`}>
          <img src={editLogo} alt="edit logo" />
        </Link>
      </td>
      <td>
        <div className="btn btn-link p-0" onClick={handleClickDelete}>
          <img src={deleteLogo} alt="edit logo" />
        </div>
      </td>
    </tr>
  );
};

export default OrderRow;