import React from "react";
import { useDispatch, useSelector } from "react-redux";

import removeRowLogo from "../assets/img//close_small_logo.svg";
import { deleteProductFromOrder, updateProductCount } from "../redux/slices/ordersSlice";
import { RootState } from "../redux/store";

export type OrderItemRowType = {
  ind: number;
  orderId: string;
  id: string;
  productCode: string;
  name: string;
  scanCode: string;
  baseUnitName: string;
  price: number;
  count: number;
  amount: number;
};

const OrderItemRow: React.FC<OrderItemRowType> = ({
  ind,
  orderId,
  id,
  productCode,
  name,
  scanCode,
  baseUnitName,
  price,
  count,
  amount,
}) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state: RootState) => state.products);
  const { orders } = useSelector((state: RootState) => state.orders);

  const currentOrder = orders.find((obj) => obj.id === orderId);
  const currentProduct = products.find((obj) => obj.id === id);

  const [ countValue, setCountValue ] = React.useState(count);

  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(value) && value !== ' ') {
      setCountValue(value);

      let editedCount = 1;
      if (value !== '') {
        editedCount = parseInt(value);
      } else {
        setCountValue(1);
      }

      if (editedCount < 1) {
        setCountValue(1);
        editedCount = 1;
      }

      dispatch(
        updateProductCount({
          orderId: currentOrder?.id,
          productObject: currentProduct,
          newCount: editedCount,
        })
      );
    } else {
      setCountValue(1);
      dispatch(
        deleteProductFromOrder({
          orderId: currentOrder?.id,
          productId: id,
        })
      );
    };

  };

  const handleRemoveRow = () => {
    if (!currentOrder?.isAccepted && window.confirm('Видалити даний товар із замовлення?')) {
      dispatch(
        deleteProductFromOrder({
          orderId: orderId,
          productId: id,
        })
      );
    };
  };

  return (
    <tr>
      <th scope="row">{ind + 1}</th>
      <td>{productCode}</td>
      <td className="text-start">{name}</td>
      <td>{productCode}</td>
      <td>{baseUnitName}</td>
      <td className="col-2">
        <div className="form-group m-0">
          <input 
            className="form-control form-control-sm" 
            type="number" 
            value={countValue} 
            onChange={e => handleCountChange(e)} 
            disabled={currentOrder?.isAccepted} />
        </div>
      </td>
      <td>{price}</td>
      <td>{amount}</td>
      <td>
        <div className="btn btn-sm btn-link p-0" onClick={handleRemoveRow}>
          <img src={removeRowLogo} alt="delete row logo" />
        </div>
      </td>
    </tr>
  );
};

export default OrderItemRow;

