import React from "react";

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
      <footer className="bg-dark text-center text-white mt-auto">
        <div className="text-center p-2">
          © {currentYear} Copyright.
          <a className="text-white" href="/">&nbsp;Business System</a>
        </div>
      </footer>
  );
};

export default Footer;
