import React from "react";
import { useSelector } from "react-redux";
import Loading from "./Loading.tsx";

import { RootState, useAppDispatch } from "../redux/store.ts";
import { fetchOrders, addNewOrder } from "../redux/slices/ordersSlice.ts";
import { fetchProducts } from "../redux/slices/productsSlice.tsx";
import { clearSelectedCategories, setOrdersOnPage } from "../redux/slices/filterSlice.ts";
import { orderLayoutOptions } from "../utils/variables";

import OrderRow from "./OrderRow.tsx";
import ActionButton from "./ActionButton.tsx";
import Filters from "./Filters.tsx";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "./Pagination.tsx";

const Orders: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orders, status } = useSelector((state: RootState) => state.orders);
  const { ordersPeriodStart, ordersPeriodEnd, ordersOnPage } = useSelector((state: RootState) => state.filters);
  const [currentPage, setCurrentPage] = React.useState(1);


  const getOrders = async () => {
    dispatch(fetchOrders({ ordersPeriodStart, ordersPeriodEnd, navigate }));
    dispatch(fetchProducts({navigate}));
  };

  React.useEffect(() => {
    getOrders();
    dispatch(clearSelectedCategories());
  }, []);

  const handleAddNewOrder = () => {
    dispatch(addNewOrder());
  };

  const onSelectItemsOnPage = (value: string) => {
    setCurrentPage(1);
    dispatch(setOrdersOnPage(Number(value)));
  };

  const firstPageIndex = (currentPage - 1) * ordersOnPage;
  const lastPageIndex = firstPageIndex + ordersOnPage;
  const currentTableData = React.useMemo(() => {
    return orders.slice(firstPageIndex, lastPageIndex);
  }, [orders, firstPageIndex, lastPageIndex]);

  let rowIndex = firstPageIndex;
  const ordersToDisplay = currentTableData.map((obj, index) => {
    rowIndex++;
    return (
    <OrderRow
      key={obj.id}
      ind={rowIndex}
      id={obj.id}
      date={obj.date}
      deliveryDate={obj.deliveryDate}
      amount={obj.amount}
      status={obj.status}
      isAccepted={obj.isAccepted}
      products={[]}
    />
  )});

  
  const noOrdersTitle = <div className="fs-5">Немає замовлень за вибраний період</div>;

  return (
    <div className="container orders">
      <h2>Журнал замовлень</h2>
      <div className="row g-2">
        <div className="col-3">
          <div className="border rounded border-secondary-subtle p-2 shadow-sm">
            <Link to="/order/new">
              <ActionButton
                title="Створити замовлення"
                click={() => handleAddNewOrder()}
                disabledState={false}
              />
            </Link>
            <div className="row row-cols-1 gy-10">
              <div className="col mt-4">
                <Filters />
              </div>
              <div className="col">
                <div className="p-3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-9">
          <div className="border rounded border-secondary-subtle p-2 shadow-sm">
            <table className="table table-light table-bordered table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">№ документа</th>
                  <th scope="col">Дата замовлення</th>
                  <th scope="col">Дата видаткової накладної</th>
                  <th scope="col">Сума замовлення</th>
                  <th scope="col">Статус</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? <Loading /> : ordersToDisplay}
              </tbody>
            </table>
            {status !== "loading" && !ordersToDisplay.length && noOrdersTitle}
            <div className="row g-3 mt-5 ">
              <div className="col-8">
                <Pagination
                  currentPage={currentPage}
                  totalCount={orders.length}
                  pageSize={ordersOnPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                  onPrevious={() => setCurrentPage((prev) => prev - 1)}
                  onNext={() => setCurrentPage((prev) => prev + 1)}
                />
              </div>
              <div className="col-4">
                <div className="row g-0">
                  <div className="col-6">
                    <span>Показувати по:</span>
                  </div>
                  <div className="col-6">
                    <select
                      className="form-select form-select-sm m-0"
                      onChange={(e) => onSelectItemsOnPage(e.target.value)}
                      defaultValue={ordersOnPage}
                    >
                      {orderLayoutOptions.map((el, index) => (
                        <option key={index} value={el} >
                          {el} елементів
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
