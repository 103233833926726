import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { setupAxios } from "../../utils/apiUtils";

export type ProductItemType = {
    id: string,
    productCode: string,
    name: string,
    baseUnitName: string,
    categoryId: string,
    price: number,
}

export type CategoryItemType = {
    id: string,
    name: string,
};

export enum Status {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
};

interface ProductsSliceState {
    products: ProductItemType[];
    categories: CategoryItemType[],
    status: Status;
};

interface GetProductsObject {
    productItems: ProductItemType[],
    categoryItems: CategoryItemType[],
};

interface FetchProductsParams {
    navigate: Function;
};

const initialState: ProductsSliceState = {
    products: [],
    categories: [],
    status: Status.LOADING,
};

export const fetchProducts = createAsyncThunk(
    "products/fetchProducts",
    async (arg: FetchProductsParams ,{ dispatch, getState }) => {
        const { userId, accessToken } = (getState() as RootState).user;
        const params = { userId };
        const { navigate } = arg;
        await setupAxios(axios, dispatch, navigate, accessToken);
        const response = await axios.get(
            "https://delivery.net.ua/ut_dev/hs/products/getProducts",
            {params}
        );
        return response.data as GetProductsObject;
    }
);

export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setProducts(state, action: PayloadAction<GetProductsObject>) {
            state.products = action.payload.productItems;
        },
        
        resetProductsState: state => initialState,
    },

    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.status = Status.LOADING;
            state.products = [];
            state.categories = [];
        });
        
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = Status.SUCCESS;
            state.products = action.payload.productItems;
            state.categories = action.payload.categoryItems;
        });

        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.status = Status.ERROR;
            state.products = [];
            state.categories = [];
        });
    },
});

export const { setProducts, resetProductsState } = productsSlice.actions;
export default productsSlice.reducer;